
.login-button {
    transition: all ease 0.2s;
}


.buttons {
    background-color: var(--button-light-color);
    text-align: center;
    color: #fff;
    font-weight: 700;
    border-radius: 2px;
    cursor: pointer;
    font-size: 18px;
    border: 1px solid var(--button-light-color);
    min-width: 130px;
    height: 45px !important;
    text-transform: 'uppercase';
    transition: all ease 0.4s;
    box-shadow: 0 1px 6px rgb(33 33 33 / 6%);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    border-radius: 32px;

}
