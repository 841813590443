.toggle {
    height: 25px;
    width: 50px;
    border-radius: 32px;
    position: relative;
    cursor: pointer;
}

.toggle-active {
    background-color: var(--active-color);
    position: relative;
}

.toggle-active .circle {
    background-color: #fff;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    margin: 4px;
}

.toggle-inactive {
    background-color: var(--border-color);
    position: relative;
}



.toggle-inactive .circle {
    position: absolute;
    background-color: #fff;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    position: absolute;
    margin: 4px;
}