.profile {
    background-color: var(--background-color);
    padding: 6px 30px;
    font-weight: 600;
    transition: all ease 0.4s;
    cursor: pointer;
    font-size: 15px;
    border-radius: 4px;
}

.profile:hover {
    background-color: var(--background-dark);
    cursor: pointer;
}