.suggestion-modal-nui {
    box-shadow: 0 .2rem 0.4rem rgba(1, 1, 1, .06) !important;
    position: absolute;
    top: 50px;
    border: 1px solid var(--border-color);
    height: 200px;
    background-color: #fff;
    width: 100%;
    z-index: 1;
    overflow-y: auto;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.suggestion-modal-item-nui {
    min-height: 45px;
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: #fff;
    flex-direction: column;
    transition: all ease 0.3s;
    padding-left: 10px;
    }

    .suggestion-modal-item-nui:hover {
        background-color: var(--background-color);
        transition: all ease 0.3s;
}
