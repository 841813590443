.single-dot {
    height: 5px;
    width: 5px;
    background-color: #777;
    border-radius: 50%;
    margin: 0px 3px;

}



.menu {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 4px 4px 15px -7px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 10;
    right: -2px;
    margin-top: 90px;
    border: 1px solid var(--border-color);
    width: 200px;
    opacity: 0;
    transition: all ease 1s;
}

.menu.menu-show {
    display: block;
    transition: all ease 1s;
    opacity: 1;

}

.menu-list {
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    border-bottom: 1px solid var(--border-color);
    padding-left: 20px;
    cursor: pointer;
    transition: all ease 0.3s;
    display: flex;
    align-items: center;
}

.menu-list:hover {
    background-color: var(--background-color);
    transition: all ease 0.3s;
}