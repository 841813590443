.table-list .single-dot {
    height: 4px;
    width: 4px;
    background-color: #777;
    border-radius: 50%;
    margin: 0px 3px;

}



.table-list .menu {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 4px 4px 15px -7px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1;
    right: -2px;
    border: 1px solid var(--border-color);
    width: 120px;
    opacity: 0;
    transition: all ease 1s;
    top: 20px;
    margin-top: 0px;
}

.table-list:hover .menu {
    display: block;
    opacity: 1;
}

.table-list .single-combine {
    border: none;
    border-color: var(--border-color);
    transition: all ease 0.3s;
}

.table-list:hover .single-combine {
    border: 1px solid var(--border-color);
    padding: 5px 4px;
    border-radius: 4px;
    transition: all ease 0.3s;
}

.table-list .menu-list {
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    border-bottom: 1px solid var(--border-color);
    padding-left: 20px;
    cursor: pointer;
    transition: all ease 0.3s;
    display: flex;
    align-items: center;
}

.table-list .menu-list:hover {
    background-color: var(--background-color);
    transition: all ease 0.3s;
}
