.half-modal-background {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    inset: 0;
    z-index: 20;
}

.half-modal-inactive {
    background-color: #fff;
    position: fixed;
    transition: all ease 0.4s;
    width: 0%;
}



.half-modal {
    background-color: #fff;
    transition: right ease 5s;
    box-shadow: 0 0 130px 5px rgb(0 0 0 / 33%);
    position: fixed;
    right: 0px;
    bottom: 0px;
    height: 100vh;
    width: 600px;
}

.half-modal-show {
    transition: right ease 2s;
    right: 0px;
}


.half-center-modal {
    background-color: #fff;
    top: 5%;
    position: fixed;
    transition: all ease 0.4s;
    margin: 0px auto;
    max-height: 90vh;
    min-height: 60vh;
    overflow-y: auto;
    left: 2%;
    right: 2%;
    border-radius: 16px;

}
