.text-center {
    text-align: center;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.table-text {
    font-size: 14px;
    font-weight: 500;
}

.box-shadow {
    -webkit-box-shadow: 12px 4px 20px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 12px 4px 20px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 2px 15px -7px rgba(0, 0, 0, 0.1);
}

.box-wrapper-shadow {
    -webkit-box-shadow: 12px 4px 20px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 12px 4px 20px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 2px 15px -7px rgba(0, 0, 0, 0.1);
    transition: all ease 0.3s;
}

.box-wrapper-shadow:hover {
    -webkit-box-shadow: 12px 4px 20px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 12px 4px 20px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 2px 20px -7px rgba(0, 0, 0, 0.3);
}

.table-title {
    font-size: 15px;
    font-weight: 600;
}

.table-bottom {
    font-size: 13px;
    color: var(--text-light-color);
    margin-top: 1px;
}
.tiny-text {
    font-weight: '400';
    color: var(--text-light-color);
    font-size: 12px;
}

.table-header-fixed {
    position: sticky;
    width: 'inherit';
    background-color: #fff;
    top: -20px;
    box-shadow: 2px 2px 20px -7px rgba(0, 0, 0, 0.3);
    transition: top ease 0.5s;
    z-index: 2;
}

.table-header-normal {
    position: relative;
    transition: top ease 0.5s;

}
.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.border-daterange {
    border:1px solid var(--border-color);
}

.single-date-range {
    border: 1px solid var(--border-color);
}