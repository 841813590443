body {
  margin: 0;
  font-family: 'QuickSand',
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2a2e34;
  }

  * {
    box-sizing: border-box;
  }
h1,h2,h3,h4,h5,h6,p {
  margin: 0;
  padding: 0;
}

:root {
  --background-color:#f6f7fb;
  --theme-main-color:#0073ea;
  --white-color:#fff;
  --theme-main-color-light:#dfdbfb;
  --theme-main-color-dark:#0060b9;
  --text-color:#404959;
  --text-light-color:#5e6b6e;
  --sidebar-active-color:#fff;
  --sidebar-inactive-color:#b4b6bd;
  --border-color:#e6ebf0;
  --hover-button-color:#5d7aef;
  --red-color:#ff6347;
  --sidebar-background:#212121;
  --sidebar-border-color:#45495a;
  --active-color:#0073ea;
  --button-light-color:#4da3ff;
  --button-color:#0073ea;
  --button-hover-color:#0060b9;
  --off-white:#f2f2f2;
  --light-color:#536e92;
  --success-color:#03fc98;
  --table-border:#f0f0f0;
  --task-verified:#33cccc;
  --off-white:#f9f9fa;
  --background-dark:#dfe4ec;
  --info-color:#e7a918;
  --info-light-color:rgba(235, 184, 64, .3);


}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
