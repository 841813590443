.tableview {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
}

.tableview.active {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.tableview img {
    height: 16px;
}
