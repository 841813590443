.show-type {
    background-color: #fff;
    z-index: 10;
    box-shadow: 4px 4px 15px -2px rgba(0, 0, 0, 0.3);
    position: absolute;
    margin-top: 5px;
    border-radius: 4px;
    padding: 10px;

}

.show-type-list {
    width: 200px;
    padding: 8px 5px;
    font-size: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.show-type-list:hover {
    background-color: var(--background-color);
    border-radius: 2px;
}