.login-input:hover {
    background-color: red;
}

input:focus {
    outline: none;
}

.unique:hover {
    border-color: #777 !important;
}