.statustile {
    padding: 5px 4px;
    width: 82px;
    font-size: 12px;
    text-align: center;


}

.task-progress-n {
    border: 1px solid rgba(235, 184, 64, .1);
    background: rgba(235, 184, 64, .1);
    color: #e7a918;
    font-weight: 600;
}

.task-success-n {
    border: 1px solid rgba(104, 208, 131, .1);
    background: rgba(104, 208, 131, .1);
    font-weight: 600;
    color: #36b056;

}

.task-incomplete-n {
    border: 1px solid rgba(222, 226, 230, .2);
    background: rgba(222, 226, 230, .2);
    color: #777;
    font-weight: 700;
}